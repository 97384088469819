<template>
  <div class="finance-invoice-review">
    <en-table-layout :tableData="tableData.data">
      <template slot="header">
        <div style="padding:10px 15px">
          <h3>开卡发票申请流程：</h3>
          <div class="headerStep">
            <p class="stepImage">
              <img src="../../../assets/step/step1.png" alt="">
              <span>移动开卡商城前台提交开票申请</span>
            </p>
            <p class="stepLine">
              <span class="line"></span>
              <span class="arrow-right"></span>
            </p>
            <p class="stepImage">
              <img style="width:66px" src="../../../assets/step/step2.png" alt="">
              <span>销售人员后台进行发票信息补录</span>
            </p>
            <p class="stepLine">
              <span class="line"></span>
              <span class="arrow-right"></span>
            </p>
            <p class="stepImage">
              <img style="width:92px" src="../../../assets/step/step3.png" alt="">
              <span>财务人员进行审核并开具发票</span>
            </p>
            <p class="stepLine">
              <span class="line"></span>
              <span class="arrow-right"></span>
            </p>
            <p class="stepImage">
              <img src="../../../assets/step/step4.png" alt="">
              <span>发票开具完成</span>
            </p>
          </div>
        </div>
      </template>
      <template slot="toolbar">
        <el-form-item label="申请开票类型" class="col-auto">
          <el-select style="width: 110px" v-model="advancedForm.invoice_type" placeholder="请选择" clearable>
            <el-option v-for="item in optionsTypes" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>

        <el-form-item label="提交时间" class="col-auto">
          <el-date-picker v-model="order_time_range" type="daterange" style="width: 250px" range-separator="-"
            start-placeholder="开始日期" end-placeholder="结束日期" @change="handleDateChange" />
        </el-form-item>
        <el-form-item>
          <el-input style="width: 290px;" size="small" placeholder="请输入关键字进行搜索" v-model.trim="params.keyWord" clearable>
            <el-select v-model="key_word" slot="prepend" style="width: 100px;">
              <el-option label="客户名称" value="customer_name"></el-option>
              <el-option label="申请批次号" value="batch_sn"></el-option>
              <el-option label="申请人名称" value="applicant_name"></el-option>
              <el-option label="申请人手机号" value="applicant_mobile"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button native-type="submit" @click.stop.prevent="searchEvent" size="small" type="primary">搜索</el-button>
        </el-form-item>
      </template>

      <template slot="table-columns">
        <el-table-column prop="batch_sn" show-overflow-tooltip label="申请批次号" width="140" fixed="left" />
        <el-table-column prop="invoice_type" show-overflow-tooltip label="申请开票类型" width="180" fixed="left">
          <template slot-scope="scope">{{ scope.row.invoice_type | invoiceTypeFilter }}</template>
        </el-table-column>
        <el-table-column prop="customer_name" show-overflow-tooltip label="客户名称" fixed="left" />
        <el-table-column prop="create_time" show-overflow-tooltip label="提交时间" width="160">
          <template slot-scope="scope">{{ scope.row.create_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}</template>
        </el-table-column>
        <el-table-column prop="applicant_name" show-overflow-tooltip label="申请人名称" width="200" />
        <el-table-column prop="applicant_mobile" show-overflow-tooltip label="申请人手机号" width="120" />
        <el-table-column prop="status" show-overflow-tooltip label="处理状态" width="80">
          <template>未处理</template>
        </el-table-column>
        <!--操作-->
        <el-table-column label="操作" width="170" fixed="right">
          <template slot-scope="{row}">
            <el-button size="mini" type="primary" @click="handleGoodsAudit(row)">
              处理
            </el-button>
            <el-button size="mini" type="primary" @click="openDetail(row)">申请详情
            </el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination v-if="tableData" slot="pagination" @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange" :current-page="tableData.page_no" :page-size="tableData.page_size"
        :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :total="tableData.data_total"></el-pagination>
    </en-table-layout>
  </div>
</template>

<script>
import * as API_SettingCustomer from '@/api/settingCustomer';
import EnTableLayout from '../../../../ui-components/TableLayout/src/main';
import XDialog from "@/components/x-dialog/x-dialog";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
export default {
  name: 'supplementaryRecordingOfInvoiceInformation',
  components: { EnTableLayout, XDialog, },
  filters: {
    statusFilter (status) {
      const statusMap = {
        0: '待审核',
        1: '待邮寄',
        2: '已邮寄',
        3: '已开票',
        4: '已拒绝'
      }
      return statusMap[status]
    },
    invoiceTypeFilter (type) {
      const temp = {
        1: '普通电子发票',
        2: '增值税专用发票-电子版',
        3: '普通纸质发票',
        4: '增值税专用发票-纸质版'
      }
      return temp[type]
    }
  },
  data () {
    return {
      order_time_range: [],
      // <!-- ; 待审核;1已通过;2已经邮寄 -->
      options: [
        {
          value: '',
          label: '全部'
        },

        {
          value: '0',
          label: '待审核'
        },
        {
          value: '1',
          label: '待邮寄'
        },
        {
          value: '2',
          label: '已邮寄'
        },
        {
          value: '3',
          label: '已开票'
        },
        {
          value: '-1',
          label: '已拒绝'
        },
      ],
      optionsTypes: [
        { value: '', label: '全部' },
        { value: 1, label: '普通电子发票' },
        { value: 3, label: '普通纸质发票' },
        { value: 2, label: '增值税专用发票-电子版' },
        { value: 4, label: '增值税专用发票-纸质版' },
      ],
      // 列表loading状态
      loading: false,
      key_word: 'customer_name',
      // 列表参数
      params: {
        page_no: 1,
        page_size: 20,
        keyWord: "",
      },
      // 商品数据
      tableData: {},

      // 高级搜索数据
      advancedForm: {
        invoice_type: '', // 发票类型
      },
      //查看商品变更记录
      dialogGoodspass: false,
      tableDatapass: [],
      expressCompany: []
    };
  },
  mounted () {
    this.GET_GoodsAuditList();
  },
  activated () {
    this.GET_GoodsAuditList();
  },

  methods: {
    //处理
    handleGoodsAudit (row) {
      this.$store.dispatch("addVisitedViews", this.$route);
      this.$router.push({
        name: "InformationSupplementation",
        params: {
          id: row.id,
        },
      });
    },
    //详情
    openDetail (row) {
      this.$store.dispatch("addVisitedViews", this.$route);
      this.$router.push({
        name: "toolsCardLookReview",
        params: {
          id: row.id,
        },
      });
    },

    /** 搜索事件触发 */
    searchEvent (data) {
      this.params.page_no = 1;
      this.GET_GoodsAuditList();
    },

    //格式化时间控件 提交时间
    handleDateChange () {
      if (
        this.order_time_range &&
        this.order_time_range.length
      ) {
        if (typeof this.order_time_range[0] === "string") {
          this.params.start_time =
            new Date(this.order_time_range[0]).getTime() / 1000;
          this.params.end_time =
            new Date(this.order_time_range[1]).getTime() / 1000 +
            86400;
        } else {
          this.params.start_time =
            this.order_time_range[0].getTime() / 1000;
          this.params.end_time =
            this.order_time_range[1].getTime() / 1000 + 86400;
        }
      } else {
        this.params.start_time = "";
        this.params.end_time = "";
      }
    },

    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_GoodsAuditList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_GoodsAuditList();
    },

    /** 获取列表数据 */
    GET_GoodsAuditList () {
      this.loading = true;
      let params = {
        ...this.params,
        ...this.advancedForm
      };
      params[this.key_word] = params.keyWord
      delete params.keyWord
      API_SettingCustomer.getRealityInvoiceList(params)
        .then(response => {
          this.loading = false;
          this.tableData = response;
        })
        .catch(() => (this.loading = false));
    },
  }
};
</script>

<style lang="scss" scoped>
.goods-image {
  width: 50px;
  height: 50px;
}

.headerStep {
  display: flex;

  p {
    margin: 0
  }

  .stepImage {
    display: flex;
    flex-direction: column;
    width: 200px;
    justify-content: center;
    align-items: center;

    img {
      width: 70px;
    }

    span {
      margin-top: 15px;
    }
  }

  .stepLine {
    display: flex;
    align-items: center;
    margin: 0 10px 39px;

    .line {
      display: inline-block;
      width: 80px;
      height: 4px;
      background: #333;
    }

    .arrow-right {
      display: inline-block;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 12px solid transparent;
      border-left: 10px solid #333;
    }
  }
}

.goods-picker-title {
  border-bottom: 1px solid #ccc;
  height: 51px;
  line-height: 51px;
  text-align: left;
  background-color: rgba(224, 228, 238, 1);
  font-weight: 600;
  border-radius: 5px;
}

.goods-picker-title span {
  background-color: #1a43a9;
  width: 4px;
  height: 14px;
  display: inline-block;
  margin-right: 14px;
  margin-bottom: 3px;
}

.el-radio {
  margin-top: 6px;
}

.fileName {
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}
</style>
